<template>
  <div class="form">
    <div class="background background_blue background_about">
      <div class="background__image background__image_about"></div>
    </div>

    <div class="wrapper">
      <div class="content">
        <div class="content__container about">
          <div class="about__title">{{ 'about' | localize }}</div>
          <TextBlock class="about__text about__text_1" :id="6" />

          <Services />

          <TextBlock class="about__text" :id="30" />

          <div class="about__hiw">
            <div>{{ 'about_text' | localize }}</div>
            <a class="hiw" :href="$store.state.params ? $store.state.params.how_it_works : '#'" target="_blank">
                <div class="hiw_button">{{ "how_it_works" | localize }}</div>
                <div class="hiw_icon"></div>
            </a>
          </div>

          <TextBlock class="about__text" :id="31" />
          <TextBlock class="about__text" :id="32" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Services from '@/components/service/List.vue'
import TextBlock from '@/components/Text.vue'

export default {
  name: 'About',
  components: { Services, TextBlock },
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/default/vars.scss";

  .about {
    width: 60%;

    &__title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 60px;
      line-height: 143.69%;
    }
  }

  .background__image {
    opacity: 1;
    z-index: 1;
    position: relative;

    &.background__image_about {
      background-image: url("~@/assets/images/common/about.png");
    }
  }


  #app.light-theme {
    .about {
      &__title {
        color: $black;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .background_about {
      display: block;
      position: absolute;
      width: 100vw;
      height: 56vw;
      top: 90px;

      &.background_blue {
        &:before,
        &:after {
          display: block;
          position: absolute;
          width: 100vw;
          height: 56vw;
          top: 0;
        }
        &:after {
          opacity: 0.2;
        }
      }
    }
    .about {
      width: auto;

      &__title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: calc(56vw - 230px);
      }

      &__text_1 {
        width: 40%;
        margin-bottom: 60px;
      }
    }
    #app.locale_heb {
      .about__title {
        margin-bottom: calc(56vw - 200px);
      }
    }
  }

  @media screen and (max-width: 850px) {
    .about__text_1 { width: 50%; }
  }
  @media screen and (max-width: 700px) {
    .about__text_1 { width: 70%; }
  }
  @media screen and (max-width: 500px) {
    .about__text_1 { width: 90%; }
  }
  @media screen and (max-width: 410px) {
    .about__title { margin-bottom: calc(56vw - 40px); }
    .about__text_1 { width: auto; margin-bottom: 30px; }

    #app.locale_heb {
      .about__title {
        margin-bottom: calc(56vw - 40px);
      }
    }
  }
</style>